<template>
  <div class="mt-1 mx-3">
    <div>
      <b-tabs content-class="pt-1" lazy v-model="tabIndex">
        <b-tab
          v-for="(tab, index) in tabs"
          :key="index"
          @click="onTabClick(tab, index)"
          :title="tab.text"
          :active="active(index)"
          title-item-class="lisaweb"
        >
          <keep-alive>
            <component :is="selected"></component>
          </keep-alive>
        </b-tab>
      </b-tabs>
    </div>
  </div>
</template>

<script>
import Registries from "@/components/documents/registries/index.vue";
import InsurancePolicies from "@/components/documents/insurance-policies/index.vue";
import NoMatch from "@/components/documents/no-match/index.vue";

import ShortcutsAndBreadcrumbsMixin from "@/mixins/ShortcutsAndBreadcrumbsMixin";

export default {
  mixins: [ShortcutsAndBreadcrumbsMixin],
  data() {
    return {
      path: "module.CLOU",
      tabs: [
        { name: "Registries", text: "Anagrafiche" },
        { name: "InsurancePolicies", text: "Polizze" },
        { name: "NoMatch", text: "Non Abbinati" },
      ],
      tabsGroup: "Management",
      bcs: [
        {
          text: "Documenti",
          path: "",
          level: 0,
          disabled: true,
        },
        {
          text: "Documenti",
          path: "",
          level: 1,
        },
        {
          text: "Ricerca Documenti",
          path: "",
          level: 2,
        },
        {
          text: "Polizze",
          path: "module.CLOU",
          tab: `#${this.selected}`,
          level: 3,
        },
      ],
      maxLevel: 3, // use to identify the tabs level
      selected: "Registries",
      tabIndex: 0,
    };
  },
  components: {
    Registries,
    InsurancePolicies,
    NoMatch,
  },
};
</script>

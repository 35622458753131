<template>
  <div class="mx-1" v-if="canVerb(resource, 'index')">
    <b-row>
      <b-col align="left">
        <b-button-group
          v-b-toggle:collapse-1
          class="my-2 filter-button-group"
          :title="getDefaultFilterMessage()"
        >
          <span class="when-open">
            <b-icon icon="funnel" font-scale="1.5"></b-icon>
          </span>
          <span class="when-closed">
            <b-icon icon="funnel" font-scale="1.5"></b-icon>
          </span>
          Filtra
        </b-button-group>
      </b-col>
    </b-row>

    <b-collapse visible id="collapse-1">
      <b-form @submit.prevent="onSearch(filterName)">
        <b-card class="filter">
          <b-row>
            <div class="col-md-3">
              <base-input
                name="title"
                type="text"
                label="Nome Documento"
                v-model="filter.byAttribute.title"
                placeholder="Inserisci un nome documento"
              />
            </div>
            <div class="col-md-3">
              <base-input
                name="note"
                type="text"
                label="Descrizione Documento"
                v-model="filter.byAttribute.note"
                placeholder="Inserisci una descrizione documento"
              />
            </div>
            <div class="col-md-3">
              <base-input
                name="metadata"
                type="text"
                label="Metadati"
                v-model="filter.byAttribute.metadata"
                placeholder="Inserisci metadati"
              />
            </div>
            <div class="col-md-3">
              <base-select
                name="document_type"
                label="Tipologia Documento"
                :options="documentTypeOptions"
                v-model="filter.byAttribute.document_type"
              />
            </div>
            <div class="form-group col-md-3 align-self-end">
              <b-button
                type="submit"
                variant="lisaweb"
                size="sm"
                v-b-toggle:collapse-1
                >Cerca</b-button
              >
              <b-button
                class="btn-reset"
                type="button"
                variant="lisaweb"
                size="sm"
                @click="onClearFilter(filterName)"
                >Reset</b-button
              >
            </div>
          </b-row>
        </b-card>
      </b-form>
    </b-collapse>
    <documents
      :fields="fields"
      :repository="repository"
      :resource="resource"
      :filterName="filterName"
      :ref="tableRef"
      :onlyActions="['infomodal']"
      :filterOn="{
        byRelations: ['byOnlyRelation'],
      }"
      @download="downloadDocument"
    ></documents>
  </div>
  <div v-else class="mt-3">
    <b-icon icon="info-circle" scale="1.00"></b-icon>
    Non hai i permessi
  </div>
</template>

<script>
import CustomFiltersMixin from "@/mixins/CustomFiltersMixin";
import DocumentsMixin from "@/mixins/DocumentsMixin";
import Documents from "@/components/tables/Documents.vue";
import { mapActions } from "vuex";
import BaseInput from "@/components/form/BaseInput";
import BaseSelect from "@/components/form/BaseSelect";
import { mapGetters } from "vuex";

export default {
  mixins: [CustomFiltersMixin, DocumentsMixin],
  data() {
    return {
      repository: "document",
      resource: "documents",
      tableRef: "NoMatchDocumentFilter",
      filterName: "filterNoMatchDocument",
      filter: this.initFilter(),
      insurersOptions: [],
      documentTypeOptions: [
        { value: 0, text: "Privacy" },
        { value: 1, text: "Generico" },
        { value: 2, text: "Documento di Identità" },
      ],
      fields: [
        {
          key: "title",
          label: this.getDictionary("title", "document"),
        },
        {
          key: "note",
          label: this.getDictionary("note", "document"),
        },
        {
          key: "document_type.text",
          label: this.getDictionary("document_type", "document"),
        },
        {
          key: "metadata",
          label: this.getDictionary("metadata", "document"),
        },
        {
          key: "download",
          label: this.getDictionary("download", "generated_report"),
          tdClass: "text-center",
          thStyle: {
            width: "125px",
          },
        },
      ],
    };
  },
  components: {
    Documents,
    BaseInput,
    BaseSelect,
  },
  methods: {
    initFilter() {
      let init = {
        byAttribute: {
          title: null,
          note: null,
          metadata: null,
          document_type: null,
        },
      };
      return init;
    },
    onSearch(name) {
      let criteria = this.filter;
      this.saveFilterByName({ name, criteria });
      this.removePaginationByName(name);
      this.$refs[this.tableRef].currentPage = 1;
      this.$refs[this.tableRef].fetch();
    },
    onClearFilter(name) {
      this.resetFilter();
      this.resetPagination();
      this.removeFilterByName(name);
      this.removePaginationByName(name);
      this.removeSortByName(name);
      this.$refs[this.tableRef].sortBy = "";
      this.$refs[this.tableRef].sortDesc = false;
    },
    ...mapGetters("auth", {
      getInsurers: "insurers",
    }),
    ...mapActions("filters", {
      saveFilterByName: "saveByName",
      removeFilterByName: "removeByName",
    }),
    ...mapActions("pagination", { removePaginationByName: "removeByName" }),
    ...mapActions("sorts", {
      removeSortByName: "removeByName",
    }),
    onSaveFilter(name, criteria) {
      this.saveFilterByName({ name, criteria });
    },
  },
  mounted() {
    this.insurersOptions = this.getInsurers();
  },
};
</script>
